<template>
      <span>
      <el-button key="info" type="info" text class="custom-button" @click="dialogs.speak = true">
          <el-icon class="my-icon"><Microphone /></el-icon>发言
      </el-button>
      <el-divider direction="vertical" />
  
      <el-dialog v-model="dialogs.speak" title="用户发言功能"  width="25%" :append-to-body="false">
        <el-form ref="speakForm" :model="forms.speak">
          <el-form-item label="是否匿名:">
            <el-switch v-model="forms.speak.isAnonymous"></el-switch>
          </el-form-item> 
         
          <el-form-item label="是否加密:">
            <el-switch v-model="forms.speak.isEncrypted"></el-switch>
          </el-form-item>
          <template v-if="forms.speak.isEncrypted">
            <el-form-item label="发言密码:">
              <el-input v-model="forms.speak.password" type="password" placeholder="请输入本次发言的密码" style="width: 300px;"></el-input>
            </el-form-item>
            <el-form-item label="密码提示:">
              <el-input v-model="forms.speak.passwordHint" placeholder="请输入密码提示,所有人可见" style="width: 300px;"></el-input>
            </el-form-item>
          </template>
          <el-form-item label="发言类型:">  
            <el-select v-model="forms.speak.subtype" placeholder="请选择发言的态度" style="width: 300px;">  
              <el-option  
                v-for="item in subtypeOptions"  
                :key="item.value"  
                :label="item.label"  
                :value="item.value">  
              </el-option>  
            </el-select>  
          </el-form-item>

          <el-form-item label="公开范围:">
            <el-select v-model="forms.speak.publicRange" placeholder="请选择公开范围" style="width: 300px;" @focus="loadPublicRangeOptions">
              <el-option v-for="option in publicRangeOptions" :key="option.value" :label="option.label" :value="option.value" />
            </el-select>
          </el-form-item>


          <el-form-item prop="content">
            <CustomInput
              v-model="forms.speak.content"
              style="width: 400px;"
            ></CustomInput>
          </el-form-item>
        </el-form>
  
  
          <el-divider>
            <span style="font-size: 20px;">
              <a class="my-icon" @click="submitForm('speak')">提交</a>
              <el-divider direction="vertical" />
              <a class="my-icon" @click="dialogs.speak = false">取消</a>
            </span>
          </el-divider>
          <FeedbackMessage :message="feedbackMessage" :show="showFeedback" />
  
      </el-dialog>
  
      </span>
    </template>
    
    
  <script setup>
  import { ref, reactive, defineEmits } from 'vue';
  // import { ElMessage } from 'element-plus';
  import axios from 'axios';
  import CustomInput from '../tiny_cmpt/CustomInput.vue';
  import FeedbackMessage from '../tiny_cmpt/FeedbackMessage.vue';
  const feedbackMessage = ref('');
  const showFeedback = ref(false);

  
  const dialogs = reactive({
    add: false,
    speak: false,
  });
  
  const forms = reactive({
    add: {
      itemsubtype:'',
      itemtitle:'',
      itemcontent:'',
      
    },
    speak: {
      isEncrypted: false,
      isAnonymous: false,
      password: '',
      passwordHint: '',
      content: '',
      subtype:'',
      publicRange: 99999999,
    },
  });
  
  
  const publicRangeOptions = ref([{ label: '所有用户', value: 99999999 }]); // 将选项定义为响应式变量，并添加默认选项
  const oprange = {
      user_id: localStorage.getItem('userid'), // 从 localStorage 获取用户ID（假设存在）
      op_type: '获取范围值',
    };

const loadPublicRangeOptions = async () => {
  try {
    const response = await axios.post('https://www.aworld.wiki/api/opinfo/get_oplist/', oprange);
    
    // 处理返回的数据，提取item0id和item0title
    const dynamicOptions = response.data.map(item => ({
      label: item.item0title,
      value: item.item0id
    }));

    // 添加固定的选项
    publicRangeOptions.value = [
      { label: '所有用户', value: 99999999 },
      { label: '仅我本人', value: 10000000 },
      ...dynamicOptions // 合并动态选项
    ];
  } catch (error) {
    console.error('Failed to load public range options:', error);
    feedbackMessage.value = '加载失败，请重试'; // 添加反馈消息
    showFeedback.value = true; // 显示反馈
  }
};





  const subtypeOptions = [
    {
      value: '求助启示',
      label: '求助启示',
    },
    {
      value: '时事新闻',
      label: '时事新闻',
    },
    {
      value: '紧急通知',
      label: '紧急通知',
    },
    {
      value: '感悟思考',
      label: '感悟思考',
    },
    {
      value: '名人名言',
      label: '名人名言',
    },
    {
      value: '经验分享',
      label: '经验分享',
    },
    {
      value: '行动实践',
      label: '行动实践',
    },
    {
      value: '重要公告',
      label: '重要公告',
    },


  ];

  
  const speakForm = ref(null);
  
  const submitForm = (formName) => {
    if (formName === 'speak' && speakForm.value) {
      speakForm.value.validate((valid) => {
        if (valid) {
          sendSpeakData();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  };
  
  const sendSpeakData = async () => {
    // 添加内容不能为空的判断

    //这里我想判断，如果是选择了加密，那么密码和密码提示不能为空
    if (forms.speak.isEncrypted && (!forms.speak.password || !forms.speak.passwordHint)) {
      feedbackMessage.value = '密码和提示不能为空'; // 添加反馈消息
        showFeedback.value = true; // 显示反馈
        return; // 终止函数执行
    }

    if (!forms.speak.content.trim()) {
        feedbackMessage.value = '内容不能为空'; // 添加反馈消息
        showFeedback.value = true; // 显示反馈
        return; // 终止函数执行
    }

    const speakData = {
      isEncrypted: forms.speak.isEncrypted,
      isAnonymous: forms.speak.isAnonymous,
      password: forms.speak.password,
      passwordHint: forms.speak.passwordHint,
      content: forms.speak.content,
      subtype: forms.speak.subtype,
      publicRange: forms.speak.publicRange,
      userid: localStorage.getItem('userid'), // 从本地缓存获取用户信息
      username: localStorage.getItem('username'), // 从本地缓存获取用户信息
    };
  
    try {
      const response = await axios.post('https://www.aworld.wiki/api/iteminfo/add_speak/', speakData);
        feedbackMessage.value = response.data.message || '提交成功';
        showFeedback.value = true;
        dialogs.speak = false;  // 在这里关闭对话框

        emit('refreshList'); // 触发 refreshList 事件，通知父组件刷新列表

    } catch (error) {
      feedbackMessage.value = '提交失败，请稍后重试';
      showFeedback.value = true;
    } finally {
    // 重置表单数据
    resetForm();
  }
  
    // 3秒后隐藏反馈信息
    setTimeout(() => {
      showFeedback.value = false;
    }, 3000);  // 改为3000毫秒，即3秒
  };

  const resetForm = () => {
  // 使用 forms.speak 而不是 form
  forms.speak.subtype = '';
  forms.speak.publicRange = 99999999;
  forms.speak.content = '';  // 注意：这里不应该使用 contentPlaceholder.value
  forms.speak.isAnonymous = false;
  forms.speak.isEncrypted = false;
  forms.speak.password = '';
  forms.speak.passwordHint = '';
};
  
  
  const emit = defineEmits(['refreshList']); // 定义 emit 事件
  
  </script>
  ​
  
    
    <style scoped>
    /* 以下代码时用来放大对话框的各个元素的字体大小 */
    .el-form-item /deep/ .el-form-item__label {
      font-size: 18px;
      /* 或其他你想要的字体大小 */
    }
  
  
  
    </style>
  
  
  
  
  