
<!-- 这是用以前的数据表结构表示的，作废 -->
<template>
  <span>
   
    <span  v-if="itemKey==='操作用户'">
      <!-- 如果是匿名，uid1为90000000 -->
      <span v-if="itemObject.fields.uid1!==90000000">
            <router-link :to="{ name: 'DetailPage', params: { id: itemObject.fields.uid1 } }" target="_blank" style="text-decoration: none;">
              <span class="a_black"><b>{{ itemObject.fields.uname}}</b></span>
            </router-link>
      </span>
      <span v-if="itemObject.fields.uid1===90000000">匿名用户</span>
    </span>

<!-- 这里需要修改，原来明文和密文不统一，但现在统一格式了， -->
    <span  v-if="itemKey==='用户言论'"> 
        <router-link :to="{ name: 'DetailPage', params: { id: itemObject.pk } }" target="_blank" style="text-decoration: none;">
            <div v-if="itemObject.fields.fymi===0" style="color: brown;display: inline-block;" v-html="itemObject.fields.fy"></div>
            <span v-if="itemObject.fields.fymi!==0">  
              <el-tooltip content="<h2>点击前往详情页进行解密</h2><h2>click to the detailpage for decrypting</h2>" raw-content placement="top-start">
                <span style="background-color:lightgrey;font-weight: bold;" class="a_brown">{{itemObject.fields.fymi}}字密文</span>
              </el-tooltip>
            </span>  
        </router-link>
   </span>

   <span v-if="itemKey==='通用元素0'">
        <router-link :to="{ name: 'DetailPage', params: { id: itemObject.fields.id0 || 10000000 } }" target="_blank" class="a_grey">
            <div v-if="itemObject.fields.type0!=='fayanye'"  class="text-ellipsis">{{itemObject.fields.title0}}</div>
          <!-- <el-tooltip :content="'<h2>'+itemObject.fields.title0+'<h2>'" raw-content placement="top-start"> -->
            <div v-if="itemObject.fields.type0==='fayanye'" class="text-ellipsis" v-html="itemObject.fields.title0"></div>
            <!-- //这里的title0是一个组合字符串，包括用户xxx的言论“言论的文本”，只展示前二十个字，其后用神略号和引号 -->
          <!-- </el-tooltip> -->
        </router-link>
    </span>

    <span v-if="itemKey==='通用元素1'">
      <router-link :to="{ name: 'DetailPage', params: { id: itemObject.fields.id1 || 10000000 } }" target="_blank" class="a_grey">
            <div v-if="itemObject.fields.type1!=='fayanye'"  class="text-ellipsis">{{itemObject.fields.title1}}</div>
          <!-- <el-tooltip :content="'<h2>'+itemObject.fields.title1+'<h2>'" raw-content placement="top-start"> -->
            <div v-if="itemObject.fields.type1==='fayanye'" class="text-ellipsis" v-html="itemObject.fields.title1"></div>
          <!-- </el-tooltip> -->
        </router-link>
    </span>

    <span  v-if="itemKey==='操作附言'">
      <span v-if="itemObject.fields.fymi===0" style="color: darkblue;">{{ itemObject.fields.fy || '无。' }} </span>
      <span v-if="itemObject.fields.fymi!==0">  
        <el-tooltip content="<h2>点击解密</h2><h2>click to decrypt</h2>" raw-content placement="top-start">
          <span @click="dialog0Visible = true" class="a_white"  style="background-color:lightgrey;color: darkblue;">{{itemObject.fields.fymi}}字密文</span>
        </el-tooltip>
      </span>  
    </span>


<!-- 这里可以加入已经有的数据，用户的密码提示 -->
        <el-dialog v-model="dialog0Visible" title="解码密文" width="30%" :append-to-body="true">  
          <el-form :model="form" :rules="rules" ref="form">   
            <el-form-item label="发言密码：" prop="age">  
              <el-input placeholder="请从发言用户处获取密码" style="width: 300px;"></el-input>  
            </el-form-item>  
          </el-form>  
          <el-dialog-footer>  
            <div style=" text-align: center;font-size: 20px;">
              <a  class="my-icon">提交</a>
              <el-divider direction="vertical" />
              <a  class="my-icon" @click="dialog0Visible = false">取消</a>
            </div>
              <br>
            <el-form-item label="解密结果：" prop="age">  
              <div class="encrypted-text-span" style="width: 300px;">测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试测试</div>
            </el-form-item>  
          </el-dialog-footer>  
        </el-dialog> 

  </span>
</template>

<script>
import { ref} from 'vue';

export default {
  name: 'ItemSpan',


  props: {
    itemKey: {
      type: String,
      required: false,
    },

    itemObject: {  
      type: Object,  
      required: false,
    },

  },
  setup(props) {

 

    const dialog0Visible = ref(false);  


    const itemKey = props.itemKey;
    const itemObject = props.itemObject;


    return {

      dialog0Visible,

      itemObject,  
      itemKey,


      
    };
  }

}

</script>


<style scoped>
  .text-ellipsis {  
    display: inline-block; /* 防止div独占一行 */  
    max-width: 600px; /* 设置最大宽度为200px */  
    white-space: nowrap; /* 防止文本换行 */  
    overflow: hidden; /* 隐藏超出容器的文本 */  
    text-overflow: ellipsis; /* 文本超出时显示省略号 */  
    font-size: 17px;
  }  

</style>  