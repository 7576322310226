<template>

<!-- 拟作废 -->
<!-- 拟作废 -->
<!-- 拟作废 -->
<!-- 拟作废 -->
<!-- 拟作废 -->
<!-- 拟作废 -->
<!-- 拟作废 -->
<!-- 拟作废 -->

  <!-- 这是用在某些页面里，放在新增页面、或者用户动态模块的最上方，用来显示一些按钮，比如新增、发言、查询、上传等等。 -->
  <!--  diolog中要设计一个 :append-to-body="true"-->
    <!-- 新增功能，加一个工作日志的类型 -->
    <span>
    <el-button key="info" type="info" text class="custom-button" v-if="showprops.includes('_apage') && propitemtype!=='发言'" @click="dialogs.add = true">
        <el-icon class="my-icon"><Plus /></el-icon>新增
    </el-button>

    <el-button key="info" type="info" text class="custom-button" v-if="showprops==='SubPage01' || propitemtype==='发言'" @click="dialogs.speak = true">
        <el-icon class="my-icon"><Microphone /></el-icon>发言
    </el-button>

    <el-button key="info" type="info" text class="custom-button" v-if="showprops==='SubPage_debate'">
        <el-icon class="my-icon"><Microphone /></el-icon>立论
    </el-button>

    <el-button key="info" type="info" text class="custom-button" v-if="showprops==='SubPage02'">
        <el-icon class="my-icon"><Refresh /></el-icon>换一批
    </el-button>
    <!-- 只用于推荐阅读的环节 -->

    <el-button key="info" type="info" text class="custom-button" v-if="showprops==='SubPage05'">
        <el-icon class="my-icon"><Calendar /></el-icon>查询
    </el-button>
    <!-- 只用于subpage05历史动态的环节 -->

    <el-button key="info" type="info" text class="custom-button" v-if="showprops==='SubPage51'">
        <el-icon class="my-icon"><Upload /></el-icon>上传
    </el-button>
    
    <el-button key="info" type="info" text class="custom-button" v-if="showprops==='SubPage51'">
        <el-icon class="my-icon"><ChatLineSquare /></el-icon>回复
    </el-button>
    <el-divider direction="vertical" />



<!-- 这里需要判断，区分元素的类型，例如文章、段落、标签、物品等，不同的元素类型可能需要不同的属性分类选择框 -->
    <el-dialog v-model="dialogs.add" title="新增各种元素" :append-to-body="true"  width="25%">
      <el-form ref="addForm" :model="forms.add">

        <el-form-item :label="`${propitemtype}标题:`">
            <el-input v-model="forms.add.itemtitle" placeholder="请输入该元素的标题或名称" style="width: 300px;"></el-input>
        </el-form-item>
        <el-form-item label="属性分类:"  v-if="propitemtype === '文章' || propitemtype === '段落'">  
          <el-select v-model="forms.add.itemsubtype" placeholder="请选择该元素的属性分类" style="width: 300px;">  
            <template v-if="propitemtype === '文章'">
              <el-option
                v-for="item in itemtypeOptions1"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </template>
            <template v-else-if="propitemtype === '段落'">
              <el-option
                v-for="item in itemtypeOptions2"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </template>
          </el-select>  
        </el-form-item>
        <el-form-item prop="itemcontent">
          <CustomInput
            v-model="forms.add.itemcontent"
            style="width: 400px;"
          ></CustomInput>
        </el-form-item>

      </el-form>
      <!-- <template #footer>
        <el-button @click="dialogs.add = false">取消</el-button>
        <el-button type="primary" @click="submitForm('add')">确定</el-button>
      </template> -->

      <el-divider>
          <span style="font-size: 20px;">
            <a class="my-icon" @click="submitForm('additem')">提交</a>
            <el-divider direction="vertical" />
            <a class="my-icon" @click="dialogs.speak = false">取消</a>
          </span>
      </el-divider>




    </el-dialog>





<!-- 以下是用户发言时的信息输入框 -->
    <el-dialog v-model="dialogs.speak" title="用户发言功能"  width="25%" :append-to-body="false">
      <el-form ref="speakForm" :model="forms.speak">
        <el-form-item label="是否匿名:">
          <el-switch v-model="forms.speak.isAnonymous"></el-switch>
        </el-form-item> 
       
        <el-form-item label="是否加密:">
          <el-switch v-model="forms.speak.isEncrypted"></el-switch>
        </el-form-item>
        <template v-if="forms.speak.isEncrypted">
          <el-form-item label="发言密码:">
            <el-input v-model="forms.speak.password" type="password" placeholder="请输入本次发言的密码" style="width: 300px;"></el-input>
          </el-form-item>
          <el-form-item label="密码提示:">
            <el-input v-model="forms.speak.passwordHint" placeholder="请输入密码提示,所有人可见" style="width: 300px;"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="发言类型:">  
          <el-select v-model="forms.speak.subtype" placeholder="请选择发言的态度" style="width: 300px;">  
            <el-option  
              v-for="item in subtypeOptions"  
              :key="item.value"  
              :label="item.label"  
              :value="item.value">  
            </el-option>  
          </el-select>  
        </el-form-item>
<!-- 这个公开范围以后可以更优化，加入多选和远程查找 -->
        <el-form-item label="公开范围:">  
          <el-select
            v-model="forms.speak.publicRange"
            placeholder="发言的范围,暂不能用"
            style="width: 300px"
          >
            <el-option
              v-for="item in publicRangeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="content">
          <CustomInput
            v-model="forms.speak.content"
            style="width: 400px;"
          ></CustomInput>
        </el-form-item>
      </el-form>


        <el-divider>
          <span style="font-size: 20px;">
            <a class="my-icon" @click="submitForm('speak')">提交</a>
            <el-divider direction="vertical" />
            <a class="my-icon" @click="dialogs.speak = false">取消</a>
          </span>
        </el-divider>
        <FeedbackMessage :message="feedbackMessage" :show="showFeedback" />

    </el-dialog>

    </span>
  </template>
  
  
<script setup>
import { ref, reactive } from 'vue';
// import { ElMessage } from 'element-plus';
import axios from 'axios';
import CustomInput from '../tiny_cmpt/CustomInput.vue';
import FeedbackMessage from '../tiny_cmpt/FeedbackMessage.vue';
const feedbackMessage = ref('');
const showFeedback = ref(false);
// 定义 props
const props = defineProps({
  showprops: {
    type: String,
    default: '111'
  },
  propitemtype: {
    type: String,
    default: '111'
  },
});

const dialogs = reactive({
  add: false,
  speak: false,
});

const forms = reactive({
  add: {
    itemsubtype:'',
    itemtitle:'',
    itemcontent:'',
    
  },
  speak: {
    isEncrypted: false,
    isAnonymous: false,
    password: '',
    passwordHint: '',
    content: '',
    subtype:'',
    publicRange: 99999999,
  },
});




const publicRangeOptions = [
  {
    value: 99999999,
    label: '所有人可见',
  },
  {
    value: 222,
    label: 'Option2',
  },
  {
    value: 333,
    label: 'Option3',
  },
  {
    value: 4444,
    label: 'Option4',
  },
  {
    value: 555,
    label: 'Option5',
  },
];

const subtypeOptions = [
  {
    value: 'Opt1',
    label: 'Option1',
  },
  {
    value: 'Opt2',
    label: 'Option2',
  },
  {
    value: 'Opt3',
    label: 'Option3',
  },
  {
    value: 'Opt4',
    label: 'Option4',
  },
  {
    value: 'Opt5',
    label: 'Option5',
  },
];

const itemtypeOptions1 = [
  {
    value: 'Opt1',
    label: 'Option1',
  },
  {
    value: 'Opt2',
    label: 'Option2',
  },
  {
    value: 'Opt3',
    label: 'Option3',
  },
  {
    value: 'Opt4',
    label: 'Option4',
  },
  {
    value: 'Opt5',
    label: 'Option5',
  },
];

const itemtypeOptions2 = [
  {
    value: 'Opt1',
    label: 'Option1',
  },
  {
    value: 'Opt2',
    label: 'Option2',
  },
  {
    value: 'Opt3',
    label: 'Option3',
  },
  {
    value: 'Opt4',
    label: 'Option4',
  },
  {
    value: 'Opt5',
    label: 'Option5',
  },
];

const speakForm = ref(null);

const submitForm = (formName) => {
  if (formName === 'speak' && speakForm.value) {
    speakForm.value.validate((valid) => {
      if (valid) {
        sendSpeakData();
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }
};

const sendSpeakData = async () => {
  const speakData = {
    isEncrypted: forms.speak.isEncrypted,
    isAnonymous: forms.speak.isAnonymous,
    password: forms.speak.password,
    passwordHint: forms.speak.passwordHint,
    content: forms.speak.content,
    subtype: forms.speak.subtype,
    publicRange:forms.speak.publicRange,
    userid: 10000001, // 从本地缓存获取用户信息
    username: 'zhao', // 从本地缓存获取用户信息
  };

  
  try {
    const response = await axios.post('https://www.aworld.wiki/api/iteminfo/add_speak/', speakData);
    
   
      feedbackMessage.value = response.data.message || '提交成功';
      showFeedback.value = true;
      dialogs.speak = false;  // 在这里关闭对话框

  } catch (error) {
    feedbackMessage.value = '提交失败，请稍后重试';
    showFeedback.value = true;
  }

  // 3秒后隐藏反馈信息
  setTimeout(() => {
    showFeedback.value = false;
  }, 3000);  // 改为3000毫秒，即3秒
};


</script>
​

  
  <style scoped>
  /* 以下代码时用来放大对话框的各个元素的字体大小 */
  .el-form-item /deep/ .el-form-item__label {
    font-size: 18px;
    /* 或其他你想要的字体大小 */
  }



  </style>




