<template>
  <div>
    <el-row class="firstrow">
      <!-- <CreatenewRow :showprops='$options.name'></CreatenewRow> -->
      <!-- <ShowlistRow  :showprops='$options.name' @request-data="requestDataFromChild" :showlistnum="returnlistnum"></ShowlistRow> -->
    </el-row>

    <div v-for="item in currentTableData" :key="item.pk" style="font-size: 18px;line-height: 18px;">
          <div v-if="item.fields.cztype === '发言'">
            <el-row class="firstrow">
              <span><ItemSpan itemKey="操作用户" :itemObject="item" ></ItemSpan></span>
              <span>发表言论<b>:</b></span>
              <span><ItemSpan itemKey="用户言论" :itemObject="item" ></ItemSpan></span>
            </el-row>
          </div>

          <div v-if="item.fields.cztype === '分享'">
            <el-row class="firstrow">
              <span><ItemSpan itemKey="操作用户" :itemObject="item" ></ItemSpan></span>
              <span>分享了</span>
              <span><ItemSpan itemKey="通用元素0" :itemObject="item" ></ItemSpan></span>
              <span v-if="item.fields.fymi!==0">&nbsp;附言<b>:</b><span><ItemSpan itemKey="操作附言" :itemObject="item" ></ItemSpan></span></span>
            </el-row>
          </div>

          <div v-if="item.fields.cztype === '评论'">
            <el-row>
              <span><ItemSpan itemKey="操作用户" :itemObject="item" ></ItemSpan></span>
              <span>评论了</span>
              <span><ItemSpan itemKey="通用元素0" :itemObject="item" ></ItemSpan></span>
              <span><b>:</b></span>
              <span><ItemSpan itemKey="操作附言" :itemObject="item" ></ItemSpan></span>
            </el-row>
          </div>

          <div v-if="item.fields.cztype === '屏蔽'">
            <el-row>
              <span><ItemSpan itemKey="操作用户" :itemObject="item" ></ItemSpan></span>
              <span>屏蔽了</span>
              <span><ItemSpan itemKey="通用元素0" :itemObject="item" ></ItemSpan></span>
              <span>&nbsp;附言<b>:</b></span>
              <span><ItemSpan itemKey="操作附言" :itemObject="item" ></ItemSpan></span>
            </el-row>
          </div>
 
          <div v-if="item.fields.cztype === '关注'">
            <el-row>
              <span><ItemSpan itemKey="操作用户" :itemObject="item" ></ItemSpan></span>
              <span>关注了</span>
              <span><ItemSpan itemKey="通用元素0" :itemObject="item" ></ItemSpan></span>
              <span>&nbsp;附言<b>:</b></span>
              <span><ItemSpan itemKey="操作附言" :itemObject="item" ></ItemSpan></span>
            </el-row>
          </div>

          <div v-if="item.fields.cztype === '补充标签'">
            <el-row>
              <span><ItemSpan itemKey="操作用户" :itemObject="item" ></ItemSpan></span>
              <span>向标签</span>
              <span><ItemSpan itemKey="通用元素0" :itemObject="item" ></ItemSpan></span>
              <span>添加了</span>
              <span><ItemSpan itemKey="通用元素1" :itemObject="item" ></ItemSpan></span>
              <span>&nbsp;附言<b>:</b></span>
              <span><ItemSpan itemKey="操作附言" :itemObject="item" ></ItemSpan></span>
            </el-row>
          </div>

          <div v-if="item.fields.cztype === '关联'">
            <el-row>
              <span>
                <span><ItemSpan itemKey="操作用户" :itemObject="item" ></ItemSpan></span>
                <span>关联了</span>
                <span><ItemSpan itemKey="通用元素0" :itemObject="item" ></ItemSpan></span>
                <span>和</span>
                <span><ItemSpan itemKey="通用元素1" :itemObject="item" ></ItemSpan></span>
                <span>&nbsp;附言<b>:</b></span>
                <span><ItemSpan itemKey="操作附言" :itemObject="item" ></ItemSpan></span>
              </span>
            </el-row>
          </div>  
         
          <ReplySpan :itemObject1="item.fields"></ReplySpan>
          <el-divider />
    </div>
    <br>
    <el-pagination  
      v-if="tableData.length>0"
      background
      @size-change="handleSizeChange"  
      @current-change="handleCurrentChange"  
      :current-page="currentPage"  
      :page-sizes="[10, 20, 30, 40]"  
      :page-size="pageSize"  
      layout="prev, pager, next"  
      :total="tableData.length">  
      ></el-pagination>  




  </div>


</template>
<script>
// import { ref} from 'vue';  
import { ref, computed, onMounted  } from 'vue';  
import axios from 'axios'; 
import ItemSpan from '../components/tiny_cmpt/ItemSpan.vue';
import ShowlistRow from '../components/CommonComp/ShowlistRow.vue';
import CreatenewRow from '@/components/CommonComp/CreatenewRow.vue';
// import EmptyImage from '@/components/tiny_cmpt/EmptyImage.vue';
import ReplySpan from '@/components/tiny_cmpt/ReplySpan.vue';
import { ElLoading } from 'element-plus'


export default {
  name: 'SubPage01',
  components: {
    ItemSpan,
    ShowlistRow,
    CreatenewRow,
    ReplySpan



  },
  setup() {
    const tableData = ref([]);
    const currentPage = ref(1);  
    const pageSize = ref(10);  
    const returnlistnum = ref(0);

    const currentTableData = computed(() => {  
      const start = (currentPage.value - 1) * pageSize.value;  
      const end = start + pageSize.value;  
      return tableData.value.slice(start, end);  
    });  
  
    const handleSizeChange = (val) => {  
      pageSize.value = val;  
      currentPage.value = 1; // 重置当前页码为第一页  
    };  
  
    const handleCurrentChange = (val) => {  
      currentPage.value = val;  
    };  

  

    const fetchData = async () => {  
      let loadingInstance = ElLoading.service({
        lock: true,
        text: '加载中...',
        background: 'rgba(0, 0, 0, 0.7)'
      }); // 启动加载动画
      try {  
        const response = await axios.post('https://www.zhishiren.info/api/xunhuan2024ceshi/',{userid:90000001});  
        tableData.value = await JSON.parse(response.data);
        returnlistnum.value = tableData.value.length;
        // 立即将 freshtabledata.value 设置为 false，表示开始加载新数据  
 
      } catch (error) {  
        console.error(error);  
      }  finally {  
        loadingInstance.close(); // 关闭加载动画
        
      }  
    };  
    const requestDataFromChild = () => {
      fetchData();  
    };



    return {
     
      currentPage,
      pageSize,
      handleSizeChange,
      handleCurrentChange,
      currentTableData,

      fetchData,
      requestDataFromChild,
      
      tableData,
      returnlistnum
      


    };



  },








}

</script>


<style scoped>
/* 使用自定义的类名来修改特定列的 padding */  
::v-deep .el-table__cell {
    padding-bottom: 0 !important;
    /* 或需要调整padding来去除间距 */
}
.example-showcase .el-loading-mask {
  z-index: 9;
}


</style>
