<template>
    <div>
      <el-row class="firstrow">
      
      </el-row>
    </div>
</template>
<script>
import { ref } from 'vue';


export default {
    name: 'SubPage43',
    components: {


    },
    setup() {
   

        const value2 = ref('')



        return {
            value2,

        };



    },








}

</script>


<style scoped>
.my-date-picker .el-date-picker__input {  
  width: 200px;  
}  
</style>